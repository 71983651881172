<template>
	<SideNav
		v-if="useContentStore().user.isLoggedIn"
		@logout="logout()"
	/>
  <router-view/>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>
<script>
import SideNav from '@/components/SideNav.vue'
export default {
	components: {
		SideNav
	},

	methods: {
		logout() {
			useContentStore().user.isLoggedIn = false
			this.$router.push({name: 'Login'})
		}
	}
}
</script>

<style>
@import url(/tita-assets/dist/tita.css);
@import url(./assets/backoffice.scss);
</style>