<template>
	<div class="backoffice-view list-view users">
		<div class="page-header">
			<h1>Usuarios</h1>
			<router-link
					:to="{name:'BackofficeUserCreate'}"
				>
				<button class="btn btn-primary btn-lg">
					Crear usuario
				</button>
			</router-link>
		</div>
		<div
			class="table-container"
		>
			<div class="table-tools">
				<input type="search" placeholder="Buscar un usuario" v-model="searchQuery">
			</div>
			<div
				v-if="backofficeUsers.length == 0"
			>
				<p>No hay usuarios.</p>
			</div>
			<table v-else>
				<thead>
					<th>Nombre</th>
					<th>Usuario</th>
					<th>Rol</th>
					<th></th>
				</thead>
				<tbody>
					<tr
						v-for="user in filteredData"
						:key="user.backofficeUserId"
					>
						<td>{{ user.username }}</td>
						<td>{{ user.email }}</td>
						<td>{{ roleDescription(user.roleCode) }}</td>
						<td>
							<div class="btn-container">
								
								<router-link
									:to="{
										name: 'BackofficeUserEdit',
										query: {user: user.email}
									}"
								>
									<button>
										<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
											<mask id="mask0_6_1147" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
											<rect width="24" height="24" fill="#D9D9D9"/>
											</mask>
											<g mask="url(#mask0_6_1147)">
											<path d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z" fill="#595959"/>
											</g>
										</svg>
									</button>
								</router-link>
								<button
									@click="confirmDelete(user.email)"
								>
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_6_1150)">
										<path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z" fill="#595959"/>
										</g>
										<defs>
										<clipPath id="clip0_6_1150">
										<rect width="24" height="24" fill="white"/>
										</clipPath>
										</defs>
									</svg>
								</button>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>
<script>
export default {
	name: 'BackofficeUsersList',
	data() {
		return {
			searchQuery: ''
		}
	},

	computed: {
		backofficeUsers() {
			return useContentStore().backofficeUsers
		},

		roles() {
			return useContentStore().roles
		},

		filteredData() {
      return this.backofficeUsers.filter((user) => 
				user.email.toLowerCase().includes(this.searchQuery.toLowerCase())
				|| user.username.toLowerCase().includes(this.searchQuery.toLowerCase()
			));
    },
	},

	methods: {
		confirmDelete(user) {
			if (window.confirm('Borrar usuario?')) {
				fetch(process.env.VUE_APP_URL_API + `api/backofficeUser/delete`, {
					method: 'PUT',
					headers: new Headers({
						'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
						"Content-Type": "application/json",
					}),
					body: JSON.stringify({email: user})
				})
					.then(response => response.json())
					.then(response => this.onDeleteBackofficeUser(response))
					.catch(error => console.error('Error:', error));
			}
		},

		onDeleteBackofficeUser(data) {
			if (data.code == 'SUCCESS') {
				alert('Usuario eliminado')
			}
		},

		roleDescription(roleCode) {
			return this.roles.find(role => role.code == roleCode)?.description
		}
	},

	mounted() {
		useContentStore().fetchBackofficeUsers()
		useContentStore().fetchBackofficeUserRoles()
	},

	updated() {

	}
}
</script>

<style scoped>
.page-header {
	display: flex;
	justify-content: space-between;
}
</style>