<template>
  <div class="login">
		<div class="form-container">
			<form action="">
				<div class="form-field-vertical">
					<label for="">Usuario</label>
					<input type="email"
						v-model="user.email"
						:invalid="emailError"
						:disabled="blockedUser"
						@input="emailError = null"
					>
					<p class="error" v-if="emailError">Usuario no encontrado</p>
				</div>
				<div class="form-field-vertical">
					<label for="">Contraseña</label>
					<input type="password"
						v-model="user.password"
						:invalid="passwordError"
						:disabled="blockedUser"
						@input="passwordError = null"
					>
					<p class="error" v-if="passwordError">Contraseña incorrecta</p>
				</div>
				<p v-if="blockedUser">Usuario bloqueado</p>
				<div class="btn-container">
					<button
						type="button"
						class="btn btn-primary btn-lg"
						:disabled="!isEmailValid || user.password.length == 0 || blockedUser"
						@click="login()"
					>
						Ingresar
					</button>
				</div>
			</form>
		</div>
  </div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>
<script>
export default {
  name: 'LoginView',
	data() {
		return {
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			emailError: null,
			passwordError: null,
			failedAttempts: 0,
			blockedUser: null,
			user: {
				email: '',
				password: '',
			}
		}
	},
	
	computed: {
		isEmailValid() {
			return this.user.email?.match(this.emailRegex)?.length > 0
		}
	},

	methods: {
		blockUser() {
			fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/block', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify({email: this.user.email})
			})
				.then(response => response.json())
				.then(response => this.onBlockedUser(response))
				.catch(error => console.error('Error:', error))
		},

		onBlockedUser(data) {
			if (data.code == 'BLOCKED') {
				this.blockedUser = true
			}
		},

		login() {
			fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/login', {
				method: 'POST',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}), 
				body: JSON.stringify(this.user)
			})
				.then(response => response.json())
				.then(response => this.onLogin(response))
				.catch(error => console.error('Error:', error))
		},

		onLogin(data) {
			if (data.resultDto.code == 'SUCCESS') {
				useContentStore().user = data.backofficeUserDto
				useContentStore().user.isLoggedIn = true
				this.$router.push({name: 'Home'})
			}

			else {
				this.failedAttempts++
				if (data.resultDto.code == 'BLOCKED') {
					this.blockedUser = true
				}

				if (data.resultDto.code == 'INVALID_EMAIL') {
					this.emailError = true
				}

				if (data.resultDto.code == 'INVALID_PASSWORD') {
					this.passwordError = true
				}

				if (this.failedAttempts >= 3) {
					this.blockUser()
				}
			}
		},
	}
}
</script>

<style>
#app:has(.login) {
	width: 100vw;
	height: 100vh;
	justify-content: center;
	align-items: center;
}
</style>