<template>
	<div class="backoffice-view editor-view">
		<div class="page-header">
			<h1>{{ mode == 'create' ? 'Crear' : 'Editar' }} usuario</h1>
		</div>
		<main>
			<form action="">
				<div class="form-field-vertical">
					<label>Rol</label>
					<VueSelect
						v-model="user.roleCode"
						:options="roles.map(role => ({'label': role.description, 'value': role.code}))"
					/>
				</div>
				<div class="form-field-vertical">
					<label>Nombre</label>
					<input type="text" v-model="user.username">
				</div>
				<div class="form-field-vertical">
					<label>Email</label>
					<input type="email" placeholder="Email" v-model="user.email">
					<p class="error" v-if="!isEmailValid && user.email.length > 0">Email inválido</p>
				</div>
				<div class="form-field-vertical">
					<label>Contraseña</label>
					<input type="password" placeholder="" v-model="user.password">
				</div>
				<div class="form-field-vertical">
					<label>Repetir contraseña</label>
					<input type="password" placeholder="" v-model="repeatPassword">
					<p class="error" v-if="!isPasswordValid && user.password?.length > 0 && repeatPassword?.length > 0">Las contraseñas deben ser iguales</p>
				</div>
				<div class="form-field gap8">
					<input type="checkbox" id="blockUser" v-model="user.state" true-value="BLOCKED" false-value="ENABLED">
					<label for="blockedUser">Bloqueado</label>
				</div>
				<div class="btn-container">
					<button type="button" class="btn btn-primary btn-lg" @click="createUser()" :disabled="!isFormValid">{{ mode == 'create' ? 'Crear usuario' : 'Guardar cambios' }}</button>
				</div>
			</form>
		</main>
	</div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
import VueSelect from "vue3-select-component";
import "vue3-select-component/dist/style.css";
</script>
<script>
export default {
	name: 'BackofficeUserCreate',
	data() {
		return {
			mode: undefined, // 'edit', 'create'
			emailRegex: /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+)$/,
			user: {
				roleCode: '',
				username: '',
				email: '',
				password: '',
				state: 'ENABLED'
			},
			repeatPassword: '',
		}
	},

	computed: {
		isFormValid() {
			for (const key in this.user) {
				if (this.user[key]?.length == 0 || this.user[key] == null) {
					return false
				}
			}

			return this.isEmailValid && this.isPasswordValid
		},

		isEmailValid() {
			return this.user.email?.length > 1 
			&& this.user.email?.match(this.emailRegex)?.length > 0
		},

		isPasswordValid() {
			if (this.mode == 'create') {
				return this.user.password == this.repeatPassword
			}

			else return true
		},

		roles() {
			return useContentStore().roles
		}
	},

	methods: {
		createUser() {
			let url, method;
			if (this.mode == 'edit') { method = 'PUT', url = 'update'}
			else if (this.mode == 'create') { method = 'POST', url = 'new' }

			fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/' + url, {
				method: method,
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
				body: JSON.stringify(this.user)
			})
				.then(response => response.json())
				.then(response => this.onUserCreated(response))
				.catch(error => console.error('Error:', error));
		},

		onUserCreated(data) {
			if (data.resultDto.code == 'INVALID_EMAIL') {
				alert(data.resultDto.description);
			}

			else if (data.resultDto.code == 'VALID_EMAIL') {
				this.$router.push({name: 'BackofficeUsersList'})
			}
		} 
	},

	created() {
		if (this.$route.query.user) {
			this.mode = 'edit'
			fetch(process.env.VUE_APP_URL_API + `api/backofficeUser/list`, {
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.user = data.find(user => user.email == this.$route.query.user))
				.catch(error => console.error('Error:', error));
		}

		else {
			this.mode = 'create'
		}
	},

	mounted() {
		useContentStore().fetchBackofficeUserRoles()
	}
}
</script>
