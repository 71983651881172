import { createRouter, createWebHashHistory } from 'vue-router'
import { useContentStore } from '@/stores/index.js'
import HomeView from '../views/HomeView.vue'

import LoginView from '../views/LoginView.vue'
import BackofficeUserCreate from '../views/admin/BackofficeUserCreate.vue'
import BackofficeUsersList from '../views/admin/BackofficeUsersList.vue'
import BackofficeRolesList from '../views/admin/BackofficeRolesList.vue'

import ArticlesMainView from '../views/ArticlesMainView.vue'
import ArticlesEditorView from '../views/ArticlesEditorView.vue'
import ArticlesListView from '../views/ArticlesListView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
	{
		path: '/login',
		name: 'Login',
		component: LoginView
	},
	{
		path: '/admin',
		meta: {
			rolesAllowed: ['ADMIN']
		},
		children: [
			{
				path: 'users',
				children: [
					{
						path: '',
						name: 'BackofficeUsersList',
						component: BackofficeUsersList,
					},
					{
						path: 'new',
						name: 'BackofficeUserCreate',
						component: BackofficeUserCreate
					},
					{
						path: 'edit',
						name: 'BackofficeUserEdit',
						component: BackofficeUserCreate
					},
				]
			},
			{
				path: 'roles',
				name: 'BackofficeRolesList',
				component: BackofficeRolesList
			}
		]
	},
	{
    path: '/articulos',
		meta: {
			rolesAllowed: ['EDIT']
		},
    name: 'ArticlesMainView',
    component: ArticlesMainView,
		children: [
			{
				path: '',
				name: "ArticlesListView",
				component: ArticlesListView
			},
			{
				path: 'editor',
				name: 'ArticlesEditorView',
				component: ArticlesEditorView,
			},
			{
				path: 'editor/:id',
				name: 'ArticlesEditorViewEdit',
				component: ArticlesEditorView
			},
		]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
	if (!useContentStore().user.isLoggedIn && to.name !== 'Login') {
    return { name: 'Login' }
  }

  if (to.meta.rolesAllowed && !to.meta.rolesAllowed.includes(useContentStore().user.roleCode)) {
    return { name: 'Home' }
  }

	return true
})

export default router
