<template>
	<div class="backoffice-view list-view roles">
		<div class="page-header">
			<h1>Roles</h1>
		</div>
		<div
			class="table-container"
		>
			<div class="table-tools">
				<input type="search" placeholder="Buscar un rol" v-model="searchQuery">
			</div>
			<div
				v-if="roles.length == 0"
			>
				<p>No hay roles.</p>
			</div>
			<table v-else>
				<thead>
					<th>Nombre</th>
					<th></th>
				</thead>
				<tbody>
					<tr
						v-for="role in filteredData"
						:key="role.roleId"
					>
						<td>{{ role.description }}</td>
						<td>
							<div class="btn-container">
								<!-- <button
									@click="confirmDelete(user.roleId)"
								>
									<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
										<g clip-path="url(#clip0_6_1150)">
										<path d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V9C18 7.9 17.1 7 16 7H8C6.9 7 6 7.9 6 9V19ZM9 9H15C15.55 9 16 9.45 16 10V18C16 18.55 15.55 19 15 19H9C8.45 19 8 18.55 8 18V10C8 9.45 8.45 9 9 9ZM15.5 4L14.79 3.29C14.61 3.11 14.35 3 14.09 3H9.91C9.65 3 9.39 3.11 9.21 3.29L8.5 4H6C5.45 4 5 4.45 5 5C5 5.55 5.45 6 6 6H18C18.55 6 19 5.55 19 5C19 4.45 18.55 4 18 4H15.5Z" fill="#595959"/>
										</g>
										<defs>
										<clipPath id="clip0_6_1150">
										<rect width="24" height="24" fill="white"/>
										</clipPath>
										</defs>
									</svg>
								</button> -->
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup>
import { useContentStore } from '@/stores/index.js'
</script>
<script>
export default {
	data() {
		return {
			searchQuery: ''
		}
	},

	computed: {
		filteredData() {
      return this.roles.filter((role) => {
        return (
          role.description?.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },

		roles() {
			return useContentStore().roles
		}
	},

	methods: {
	},

	mounted() {
		useContentStore().fetchBackofficeUserRoles()
	}
}
</script>