<template>
	<router-view></router-view>
</template>

<script>
export default {
  name: 'ArticlesMainView',
}
</script>
<style>
#app {
	display: flex;
}
</style>
