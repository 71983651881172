import { defineStore } from 'pinia'
export const useContentStore = defineStore('content', {
	state: () => ({ 
		user: {
			isLoggedIn: undefined
		},
		backofficeUsers: [],
		roles: [],
		articleById: null,
		articles: [],
		articleTypes: []
	}),

	getters: {
		article: (state) => (id) => {
			return state.articles.find(art => art.articleId == id)
		},

		articlesByTypeCode: (state) => (typeCode) => {
			return state.articles.filter(art => art.articleTypeCode == typeCode)
		}
	},

	actions: {
		addArticle(obj) {
			this.articles.push(obj)
		},

		editArticle(obj) {
			console.log(obj)
			let index = this.articles.indexOf(this.articles.find(art => art.id == obj.id));
			this.articles[index] = obj
		},

		disableArticle(idArray) {
			idArray.forEach(id => {
				this.article(id).active = 0
			})
		},

		fetchArticles() {
			fetch(process.env.VUE_APP_URL_API + 'api/article/list', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.articles = data)
				.catch(error => console.error('Error:', error))
		},

		fetchArticleTypes() {
			fetch(process.env.VUE_APP_URL_API + 'api/article/articleType/list', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.articleTypes = data)
				.catch(error => console.error('Error:', error))
		},

		fetchBackofficeUsers() {
			fetch(process.env.VUE_APP_URL_API + 'api/backofficeUser/list', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
			.then(response => response.json())
			.then(data => this.backofficeUsers = data)
			.catch(error => console.error('Error:', error))
		},

		fetchBackofficeUserRoles() {
			fetch(process.env.VUE_APP_URL_API + 'api/role/list', {
				method: 'GET',
				headers: new Headers({
					'Authorization': 'Basic '+btoa('tita_user:ef4515ee-5e13-423d-89f1-a2f4730ad346'), 
					"Content-Type": "application/json",
				}),
			})
				.then(response => response.json())
				.then(data => this.roles = data)
				.catch(error => console.error('Error:', error))
		}
	},
})