<template>
	<div class="editor">
		<Modal ref="ytmodal" @onConfirm="applyImage" />
		<div class="menu">
			<button
				type="button"
				:class="{ 'is-active': editor?.isActive('bold') }"
				@click="editor.chain().focus().toggleBold().run()"
			>
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M19.6 14.79C20.57 14.12 21.25 13.02 21.25 12C21.25 9.74 19.5 8 17.25 8H11V22H18.04C20.13 22 21.75 20.3 21.75 18.21C21.75 16.69 20.89 15.39 19.6 14.79ZM14 10.5H17C17.83 10.5 18.5 11.17 18.5 12C18.5 12.83 17.83 13.5 17 13.5H14V10.5ZM17.5 19.5H14V16.5H17.5C18.33 16.5 19 17.17 19 18C19 18.83 18.33 19.5 17.5 19.5Z" fill="#A6A6A6"/>
				</svg>
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor?.isActive('italic') }"
				@click="editor.chain().focus().toggleItalic().run()"
			>
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M14 8V11H16.21L12.79 19H10V22H18V19H15.79L19.21 11H22V8H14Z" fill="#A6A6A6"/>
				</svg>
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor?.isActive('highlight') }"
				@click="editor.chain().focus().toggleHighlight().run()"
			>
				<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#none"><path d="M80 0v-160h800V0H80Zm504-480L480-584 320-424l103 104 161-160Zm-47-160 103 103 160-159-104-104-159 160Zm-84-29 216 216-189 190q-24 24-56.5 24T367-263l-27 23H140l126-125q-24-24-25-57.5t23-57.5l189-189Zm0 0 187-187q24-24 56.5-24t56.5 24l104 103q24 24 24 56.5T857-640L669-453 453-669Z" fill="#A6A6A6"/></svg>
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor?.isActive('list') }"
				@click="editor.chain().focus().toggleBulletList().run()"
			>
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M8.25 14.5C7.42 14.5 6.75 15.17 6.75 16C6.75 16.83 7.42 17.5 8.25 17.5C9.08 17.5 9.75 16.83 9.75 16C9.75 15.17 9.08 14.5 8.25 14.5ZM8.25 8.5C7.42 8.5 6.75 9.17 6.75 10C6.75 10.83 7.42 11.5 8.25 11.5C9.08 11.5 9.75 10.83 9.75 10C9.75 9.17 9.08 8.5 8.25 8.5ZM8.25 20.5C7.42 20.5 6.75 21.18 6.75 22C6.75 22.82 7.43 23.5 8.25 23.5C9.07 23.5 9.75 22.82 9.75 22C9.75 21.18 9.08 20.5 8.25 20.5ZM11.25 23H25.25V21H11.25V23ZM11.25 17H25.25V15H11.25V17ZM11.25 9V11H25.25V9H11.25Z" fill="#A6A6A6"/>
				</svg>
			</button>
			<button
				type="button"
				:class="{ 'is-active': editor?.isActive('link') }"
				@click="setLink"
			>
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7.9 16C7.9 14.29 9.29 12.9 11 12.9H15V11H11C8.24 11 6 13.24 6 16C6 18.76 8.24 21 11 21H15V19.1H11C9.29 19.1 7.9 17.71 7.9 16ZM12 17H20V15H12V17ZM21 11H17V12.9H21C22.71 12.9 24.1 14.29 24.1 16C24.1 17.71 22.71 19.1 21 19.1H17V21H21C23.76 21 26 18.76 26 16C26 13.24 23.76 11 21 11Z" fill="#A6A6A6"/>
				</svg>
			</button>
			<!-- <button @click="showModal">
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M25 23V9C25 7.9 24.1 7 23 7H9C7.9 7 7 7.9 7 9V23C7 24.1 7.9 25 9 25H23C24.1 25 25 24.1 25 23ZM12.5 17.5L15 20.51L18.5 16L23 22H9L12.5 17.5Z" fill="#A6A6A6"/>
				</svg>
			</button> -->
		</div>
		<editor-content :editor="editor" />
	</div>
</template>

<script>
import StarterKit from '@tiptap/starter-kit'
import { Editor, EditorContent } from '@tiptap/vue-3'
import Image from '@tiptap/extension-image'
import Modal from '@/components/ImgUploadModal.vue'
import Link from '@tiptap/extension-link'
import Highlight from '@tiptap/extension-highlight'

export default {
	components: {
		EditorContent,
		// Link,
		Modal
	},

	props: {
		modelValue: {
			type: String,
			default: '',
		},
	},

	emits: ['update:modelValue'],

	data() {
		return {
			editor: null,
		}
	},

	watch: {
		modelValue(value) {
			// HTML
			const isSame = this.editor.getHTML() === value

			// JSON
			// const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

			if (isSame) {
				return
			}

			this.editor.commands.setContent(value, false)
		},
	},

	methods: {
		showModal(command) {
			this.$refs.ytmodal.showModal(command);
		},
		addImage(data) {
			console.log(data)
			const url = window.prompt('URL')

			if (url) {
				this.applyImage(url)
			}
		},
		applyImage(url) {
			console.log(url)
			this.editor.chain().focus().setImage({ src: url }).run()
		},

		addCommand(data) {
			if (data.command !== null) {
				console.log(data.command)
				data.command(data.data);
			}
		},

		setLink() {
			const previousUrl = this.editor.getAttributes('link').href

			if (this.editor.isActive('link')) {
				console.log('is active')
				this.editor
					.chain()
					.focus()
					.unsetMark('link')
					.run()

				return
			}

			else {
				let url = window.prompt('URL', previousUrl)
				// cancelled
				if (url === null) {
					return
				}

				if (url === '') {
					this.editor
						.chain()
						.focus()
						.extendMarkRange('link')
						.unsetLink()
						.run()

					return
				}

				else if (!url.includes('http')) {
					url = ('https://').concat(url)
				}

				this.editor
					.chain()
					.focus()
					.extendMarkRange('link')
					.setLink({ href: url })
					.run()
			}
		},
	},

	mounted() {
		this.editor = new Editor({
			extensions: [
				StarterKit,
				Image,
				Highlight,
				Link.extend({
          // inclusive: false
        })
			],
			content: this.modelValue,
			onUpdate: () => {
				// HTML
				this.$emit('update:modelValue', this.editor.getHTML())

				// JSON
				// this.$emit('update:modelValue', this.editor.getJSON())
			},
		})
	},

	beforeUnmount() {
		this.editor.destroy()
	},
}
</script>

<style>
.tiptap.ProseMirror {
	border: 1px solid var(--color-n30);
	padding: 1em;
	overflow-y: auto;
}

.editor .menu {
	display: flex;
	align-items: center;
	gap: 4px;
	margin-bottom: 4px;
}

.editor a {
	cursor: pointer;
}

.editor ul {
	list-style-position: inside;
}

.editor li>p {
	display: contents;
}

.editor button.is-active {
	outline: 1px solid blue;
	font-weight: bold;
}

.editor .menu button {
	width: 32px;
	height: 32px;
	border: none;
	outline: none;
}
</style>